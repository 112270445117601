import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hello!</h1>
    <p>
      Welcome to my digital home.<br/>
      My name is Kyriakos Sideris and I am Software Engineer working on Big Data and Machine Learning technologies.
    </p>
  </Layout>
)

export default IndexPage
